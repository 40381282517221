.btn {
    @apply relative flex h-[46px] flex-shrink-0 items-center justify-center  overflow-hidden rounded-md border text-center text-base text-interface-700  outline-0 focus:ring-0;
}
.btn .labelText {
    @apply font-theme font-semibold leading-[36px];
}
.btn.sm {
    @apply h-[38px] rounded-md pb-[1px] text-sm;
}
.btn.md {
    @apply h-12 text-base ipad:h-[38px] ipad:text-base mobile:h-[38px] mobile:text-sm;
}
.btn.base {
    @apply text-sm h-9;
}
.btn.base .labelText {
    @apply font-theme font-normal;
}
.btn.other {
    @apply h-[41px];
}
.btn.live {
    @apply bg-live rounded border border-live w-[58px] h-[20px] uppercase py-[2px] px-0;
}
.btn.live span {
    @apply text-common-white text-xs leading-none;
}
.btn.normalizeLink.normalizeLink {
    @apply inline-block h-auto p-0 font-normal border-0 text-primary-500;
}
.btn.xs {
    @apply h-[42px] px-3.5 text-sm;
}
.primary.primary {
    @apply transition-all border-transparent shadow-md bg-primary-500 text-common-white;
}
.secondary.secondary {
    @apply border-0 bg-interface-700 text-common-white hover:border-interface-700;
}
.success.success {
    @apply bg-success-600 text-common-white hover:bg-success-700;
}
.cancelled.cancelled {
    @apply bg-common-black text-common-white hover:bg-common-black;
}
.gray.gray {
    @apply bg-interface-400 text-common-white hover:bg-interface-400;
}
.outline.outline {
    @apply border-primary-500 bg-primary-100 text-primary-500;
}
.outline-interface.outline-interface {
    @apply bg-transparent shadow-sm border-interface-200 text-interface-600;
}
.dark.dark {
    @apply border-interface-600 bg-interface-600 text-interface-200;
}
.white.white {
    @apply bg-common-white text-interface-800;
}
.link.link {
    @apply h-auto max-h-[38px] border-0 py-0.5 text-primary-500;
}
.danger.danger {
    @apply border-danger-600 bg-danger-600 text-common-white;
}
.warning.warning {
    @apply border-warning-500 bg-warning-500 text-common-white;
}
.delete.delete{
    @apply p-0 border-none h-auto;
}
.link.link span {
    @apply font-normal leading-5;
}
.btn.link-xs span {
    @apply p-0 text-sm underline font-theme text-primary-500;
}
.btn .text-justify-start {
    @apply justify-start;
}
.btn .iconPosition.right {
    @apply flex-row-reverse;
}
.btn .iconPosition.right i {
    @apply ml-3 mr-0;
}
.btn .spinner {
    @apply absolute left-0 right-0 w-6 m-auto opacity-0 top-1;
}
.btn.md .spinner {
    @apply top-[11px];
}
.btn.sm .spinner {
    @apply top-1.5;
}
.btn.loading,
.btn[loading] {
    @apply text-transparent border-transparent pointer-events-none;
}
.btn.loading .spinner {
    @apply opacity-100;
}
.btn.loading .labelText {
    @apply opacity-0;
}
.btn.disabled,
.btn[disabled] {
    @apply pointer-events-none opacity-70;
}
.loading-btn{
    @apply pointer-events-none;
}
